import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import { startFeedback } from "../utils/util";

interface RatingReviewProps {
  inputText: string;
  translatedText: string;
  sourceLanguage: string;
  targetLanguage: string;
}

interface DropdownItem {
  eventKey: string;
  label: string;
}

export const FeedbackItems: React.FC<RatingReviewProps> = ({
  inputText,
  translatedText,
  sourceLanguage,
  targetLanguage,
}) => {
  const feedbackMessage = "Feedback has been submitted!";
  const feedbackHeader = "Debrecen Feedback";
  const defaultSelect = "Select a feedback option";

  const dropdownItems: DropdownItem[] = [
    { eventKey: "Translation", label: "Translation" },
    { eventKey: "Speech-to-Text", label: "Speech-to-Text" },
    { eventKey: "Text-to-Speech", label: "Text-to-Speech" },
  ];

  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [rating, setRating] = useState(0)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedValue, setSelectedValue] = useState(defaultSelect);
  const starRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (starRef.current && !starRef.current.contains(event.target as Node)) {
        setRating(0);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    startFeedback(
      inputText,
      translatedText,
      sourceLanguage,
      targetLanguage,
      rating,
      text,
      selectedValue
    );
  };

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setSelectedValue(eventKey);
    }
  };
  return (
    <>
      <div ref={starRef} className="mb-3">
        {[1, 2, 3].map((star) => {
          return (
            <span
              className="start"
              style={{
                cursor: "pointer",
                color: rating >= star ? "DodgerBlue" : "gray",
                fontSize: `35px`,
              }}
              onClick={() => {
                setRating(star);
              }}
            >
              {" "}
              ★{" "}
            </span>
          );
        })}
        <Dropdown onSelect={handleSelect} className="mb-3">
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {selectedValue}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {dropdownItems.map((item) => (
              <Dropdown.Item key={item.eventKey} eventKey={item.eventKey}>
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Form.Control
              as="textarea"
              rows={3}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter your feedback here"
            />
            <div className="button-right mt-3">
              <Button
                type="submit"
                onClick={handleShow}
                variant="primary"
                disabled={
                  rating === 0 || selectedValue === "Select a feedback option"
                }
              >
                Submit
              </Button>
            </div>
          </FormGroup>
        </Form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{feedbackHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{feedbackMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
