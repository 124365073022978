import { TranslationService } from '../services/TranslationService';
import { FeedbackService } from '../services/FeedbackService';

const translationService = TranslationService.classInstance;
const feedbackService    = FeedbackService.classInstance;

export async function startTextToSpeech(translationResult: string, targetLanguage: string, setSynthesizedAudio: (blob: Blob) => void) {
    try {
      const synthResult = await translationService.synthesiseSpeech({
        text: translationResult,
        targetLanguage
      });
  
      setSynthesizedAudio(synthResult);
    } catch (ex) {
      console.error("Error during TTS:", ex);
    }
  }

export  async function startTranslation( text: string, sourceLanguage: string, targetLanguage: string , textOnly : boolean, setSynthesizedAudio: (blob: Blob) => void) {
    try {
      if (sourceLanguage === targetLanguage)
      return text
      const translationResult = await translationService.translateText(
        {text,
        sourceLanguage,
        targetLanguage}
      );
  
      if(textOnly === false){
        startTextToSpeech(translationResult, targetLanguage, setSynthesizedAudio);
      }

      return translationResult
  
    } catch (ex) {
      console.error("Error during translation:", ex);
    }
  }

export async function startFeedback(inputText: string, outputText: string, sourceLanguage: string, targetLanguage: string, rating: number, comment: string, feedbackType: string) {
    try {
      const FeedbackResult = await feedbackService.feedback(
        {inputText, 
        outputText,
        sourceLanguage,
        targetLanguage,
        rating,
        comment,
        feedbackType
      }
      );
  
      console.log(FeedbackResult);
  
    } catch (ex) {
      console.error("Error during feedback request:", ex);
    }
  }